@import './shared/editor.variables';

.editor-new {
  .is-active {
    font-weight: 500;
  }
}

.min-h-0 > .ProseMirror {
  min-height: 0 !important;
}

tiptap-editor:not(.editor-readonly) > .ProseMirror {
  min-height: 220px;

  iframe {
    border: 8px solid #000;
    border-radius: var(--border-radius-md);
    min-width: 80px;
    min-height: 80px;
    display: block;
    outline: 0px solid transparent;
  }

  mspot-tiptap-video > div {
    @apply border border-gray-300;
    padding: 0.75rem;
    border-radius: var(--border-radius-2xl);
  }

  div[data-youtube-video],
  div[data-loom-video],
  div[data-vimeo-video],
  div[data-spotify-audio],
  div[data-wistia-video] {
    cursor: move;
    padding-right: 24px;
  }

  div[data-spotify-audio] {
    padding-bottom: 96px;
  }

  mspot-tiptap-video.selected > div {
    @apply border-primary;
  }

  .ProseMirror-selectednode iframe {
    border: 8px solid rgb(var(--primary) / 1);
  }

  img.ProseMirror-selectednode,
  .cta-btn.ProseMirror-selectednode {
    @apply border-2 border-gray-900;
    border-radius: var(--border-radius-md);
  }

  img {
    user-select: none;
    -webkit-user-select: none; /* Safari */
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
  font-size: 0.875rem;
  font-style: italic;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

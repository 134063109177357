.mat-dialog-container {
  height: inherit !important;
}

.dialog-sm {
  width: 95%;
  max-width: 320px !important;
  overflow-y: auto;
}

.dialog-384 {
  width: 95%;
  max-width: 384px !important;
  overflow-y: auto;
}

.dialog-420 {
  width: 95%;
  max-width: 420px !important;
  overflow-y: auto;
}

.dialog-500 {
  width: 95%;
  max-width: 500px !important;
  overflow-y: auto;
}

.dialog-512 {
  width: 95%;
  max-width: 32rem !important;
  overflow-y: auto;
}

.dialog-784 {
  width: 95%;
  max-width: 784px !important;
  overflow-y: auto;
}

.dialog-p-0 {
  --mat-dialog-padding: 0;
}

.dialog-bg-default {
  .mdc-dialog--open .mat-mdc-dialog-surface {
    background-color: rgb(var(--backgrounds-primary)) !important;
  }
}

.dialog-md {
  width: 95%;
  max-width: 600px !important;
  overflow-y: auto;
}

.dialog-small-lg {
  width: 95%;
  max-width: 900px !important;
  overflow-y: auto;
}

.dialog-small-lg-visible {
  width: 95%;
  max-width: 900px !important;
  overflow: visible;
}

.dialog-lg {
  width: 95%;
  max-width: 1200px !important;
  overflow-y: auto;
}

.dialog-full {
  width: 100vw;
  overflow-y: auto;

  .mat-dialog-container {
    max-width: none;
  }
}

.dialog-custom-search {
  width: calc(100% - 32px);
  max-width: 640px !important;
  --mat-dialog-padding: 0;

  .mat-mdc-dialog-container {
    max-height: calc(100vh - 48px) !important;
  }

  .mdc-dialog--open .mat-mdc-dialog-surface {
    background-color: rgb(var(--mspot-neutral-50)) !important;
    border: 1px solid rgb(var(--mspot-neutral-300));
  }
}

.dialog-zero-bottom-border-radius {
  .mat-mdc-dialog-surface {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.cdk-overlay-pane {
  max-height: 95% !important;
  border-radius: 0.75rem;
}

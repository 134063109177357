@import './editor.variables';

.comment-content,
.editor-content {
  *:first-child {
    margin-top: 0px;
  }

  .ProseMirror {
    *:first-child {
      margin-top: 0px;
    }
  }
}

.editor-content > .ProseMirror > :not([hidden]) ~ :not([hidden]),
.comment-content > .ProseMirror > :not([hidden]) ~ :not([hidden]),
.comment-content > :not([hidden]) ~ :not([hidden]),
.editor-content > :not([hidden]) ~ :not([hidden]) {
  margin-top: 1rem;
}

.comment-content {
  p {
    min-height: 1.25rem;
    overflow-wrap: break-word;
  }

  // Link
  a {
    color: rgb(var(--primary) / 1);
    word-break: break-all;
  }
  a:hover {
    text-decoration: underline;
  }
}

.editor-readonly,
.comment-content {
  a:hover {
    cursor: pointer;
  }
}

.editor-content {
  --gray-50: var(--ccp-gray-50);
  --gray-100: var(--ccp-gray-100);
  --gray-200: var(--ccp-gray-200);
  --gray-300: var(--ccp-gray-300);
  --gray-400: var(--ccp-gray-400);
  --gray-500: var(--ccp-gray-500);
  --gray-600: var(--ccp-gray-600);
  --gray-700: var(--ccp-gray-700);
  --gray-800: var(--ccp-gray-800);
  --gray-900: var(--ccp-gray-900);
  --gray-950: var(--ccp-gray-950);
  --white: var(--ccp-white);
  --black: var(--ccp-black);

  mspot-tiptap-video {
    --gray-50: var(--mspot-neutral-50);
    --gray-100: var(--mspot-neutral-100);
    --gray-200: var(--mspot-neutral-200);
    --gray-300: var(--mspot-neutral-300);
    --gray-400: var(--mspot-neutral-400);
    --gray-500: var(--mspot-neutral-500);
    --gray-600: var(--mspot-neutral-600);
    --gray-700: var(--mspot-neutral-700);
    --gray-800: var(--mspot-neutral-800);
    --gray-900: var(--mspot-neutral-900);
    --gray-950: var(--mspot-neutral-950);
  }
}

.editor-content {
  font-size: 0.9375rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  // Link
  a {
    color: rgb(var(--primary) / 1);
    word-break: break-all;
  }
  a:hover {
    text-decoration: underline;
  }

  p {
    min-height: 1.4rem;
  }

  // CTA Button
  .cta-btn {
    height: 45px;
    min-width: 50%;
    margin: 0.75rem auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: var(--border-radius-md);

    &:hover {
      filter: brightness(0.9);
    }
  }

  h1 {
    margin-top: 1.5rem;
  }
  h2 {
    margin-top: 1.25rem;
  }
  h3 {
    margin-top: 1rem;
  }

  // Unset max-height for video media-poster, otherwise it is broken
  media-poster {
    img {
      max-height: unset;
    }
  }

  // Image
  media-poster {
    img {
      max-height: unset;
    }
  }
  img {
    border: 1px solid transparent;
    border-radius: var(--border-radius-md);
    max-height: 300px;
  }

  img[data-align='center'] {
    margin: 0.75rem auto 0;
    max-width: 75%;
  }

  img[data-align='left'] {
    max-width: 75%;
    margin-right: auto;
  }

  img[data-align='right'] {
    max-width: 75%;
    margin-left: auto;
  }

  @media (min-width: 960px) {
    img[data-align='left'] {
      max-width: 50%;
    }
    img[data-align='right'] {
      max-width: 50%;
    }
    img[data-align='float-left'] {
      float: left;
      max-width: 50%;
      margin-right: 1rem;
    }
    img[data-align='float-right'] {
      float: right;
      max-width: 50%;
      margin-left: 1rem;
    }
  }

  &.is-page {
    // Image
    img {
      margin: 0 auto;
      max-height: 400px;
    }

    img[data-align='left'] {
      margin: 0 auto 0 0;
    }

    img[data-align='right'] {
      max-width: 75%;
      margin: 0 0 0 auto;
    }

    @media (min-width: 960px) {
      img:not([data-align]) {
        max-width: 100%;
        max-height: unset;
        height: auto;
      }
      img[data-align='left'] {
        max-width: 50%;
      }
      img[data-align='right'] {
        max-width: 50%;
      }
      img[data-align='float-left'] {
        float: left;
        max-width: 50%;
        margin-right: 1rem;
      }
      img[data-align='float-right'] {
        float: right;
        max-width: 50%;
        margin-left: 1rem;
      }
    }
  }

  // Headings
  h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    @apply text-gray-900;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    @apply text-gray-900;
  }

  h3 {
    @apply text-gray-800;

    font-weight: 500 !important;
    font-size: 1rem;
  }

  // Lists

  ul,
  ol {
    padding-left: 2rem;
    li {
      margin-top: 0.25rem;
    }
  }

  ul:not(:first-child),
  ol:not(:first-child) {
    margin-top: 0.25rem;
  }

  ol {
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type: decimal;
        }
      }
    }
  }

  ul {
    list-style-type: disc;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
      }
    }
  }

  li > p {
    margin-block-end: 0px;
    margin-block-start: 0px;
  }

  ul[data-type='taskList'] {
    padding-left: 0;
    list-style-type: none;
    margin-left: 1rem;
    li {
      display: flex;
      align-items: center;
      label {
        cursor: pointer;
        margin-right: 10px;
        position: relative;
        float: left;
        top: -2px;
        span {
          border-radius: 3px;
          border: 1px solid lighten(#000, 30%);
          display: block;
          height: 14px;
          margin-top: 3px;
          width: 14px;
          background-color: lighten(#000, 90%);
        }
      }
    }
    li[data-checked='true'] label span {
      border: 1px solid #000;
      background: #000
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'\
            viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z'\
            fill='%23fff'/%3E%3C/svg%3E")
        no-repeat 50%;
    }
  }

  // Code & Block
  pre {
    background: #0d0d0d;
    border-radius: 0.5rem;
    color: #fff;
    font-family: JetBrainsMono, monospace;
    padding: 0.75rem 1rem;
    font-size: 0.825rem;
    code {
    }

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-attr,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }

  blockquote {
    border-left: 2px solid rgba(13, 13, 13, 0.1);
    padding-left: 1rem;
  }

  //Video
  div[data-youtube-video],
  div[data-wistia-video],
  div[data-vimeo-video],
  div[data-loom-video] {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    // margin-top: 1rem;
  }

  div[data-spotify-audio] {
    position: relative;
    padding-bottom: 80px;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    // margin-top: 1rem;
  }

  div[data-youtube-video] iframe,
  div[data-loom-video] iframe,
  div[data-wistia-video] iframe,
  div[data-vimeo-video] iframe,
  div[data-spotify-audio] iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // Table
  table {
    border-collapse: collapse;
    overflow-x: auto;
    overflow-y: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }

      p {
        width: 100%;
        overflow-wrap: break-word;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper,
.table-wrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

mspot-space-post-preview {
  tiptap-editor {
    .tiptap {
      height: 100%;

      & div[data-youtube-video],
      & div[data-wistia-video],
      & div[data-vimeo-video],
      & div[data-loom-video],
      mspot-tiptap-video {
        height: 100%;
      }
    }
  }

  .editor-content p {
    @apply relative line-clamp-2;
  }

  .editor-content.has-more-content {
    @apply relative;

    &::before {
      @apply absolute inset-0 top-[30%] z-10 bg-gradient-to-b from-transparent to-white dark:to-[var(--white)];

      content: '';
    }
  }

  &:has(img.preview) {
    .editor-content p {
      @apply line-clamp-3;
    }
  }
}

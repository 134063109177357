@import './shared/widgets.scss';

/*******************/
/*Hover Directive*/
/*******************/

.hover-container {
  position: relative;

  &--element {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  &--icon {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  &:hover .hover-container--element {
    opacity: 0.3;
    cursor: pointer;
  }

  &:hover .hover-container--icon {
    opacity: 1;
    cursor: pointer;
  }

  .ql-editor > * {
    cursor: pointer;
  }
}

/*
* Image
*/

.cdk-drag-animating {
  transition: transform 300ms ease;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 300ms ease;
}

.drag-handle,
.drag-handle-delay,
.drag-handle-delay-80,
.drag-handle:active,
.drag-handle-delay:active,
.cdk-drag-placeholder,
.cdk-drag-placeholder .mat-expansion-panel-header {
  cursor: move !important;
}

.cdk-drag-placeholder .hover\:bg-gray-100 {
  @apply bg-gray-100;
}

.box-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.cdk-drag-placeholder,
.cdk-drop-list-dragging {
  cursor: move !important;

  .mat-expansion-panel-header,
  button,
  a,
  .box-wrapper {
    cursor: move !important;
  }

  .hover\:bg-gray-100:hover {
    background-color: none !important;
  }
  .box-wrapper:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .hide-while-dragging,
  .cdk-drag-handle:not(.drag-icon) {
    opacity: 0 !important;
  }
}

.cdk-drag-preview {
  .drag-handle,
  .drag-handle-delay {
    height: 24px;
    width: 24px;
    opacity: 1;
  }
  .drag-handle-delay-80 {
    height: 24px;
    width: 24px;
    opacity: 0.8;
  }
  .chapter-icon,
  .file-icon {
    width: 0 !important;
    opacity: 0 !important;
  }
  .file div:first-child {
    background-color: white;
    overflow: hidden;
  }
  .box-wrapper:hover {
    background-color: transparent;
  }
}

.cdk-drag-placeholder {
  .drag-handle,
  .drag-handle-delay {
    height: 24px !important;
    width: 24px !important;
    opacity: 1 !important;
  }
  .chapter-icon,
  .file-icon {
    width: 0 !important;
    opacity: 0 !important;
  }
}

.cdk-drag:not(.cdk-drag-preview) {
  .media-object,
  .post,
  .subchapter-header,
  .chapter-panel-header,
  .course-drag,
  .offer--card,
  .dnd-card,
  .course-item,
  .file,
  .box-drag,
  .drag-item {
    .drag-handle,
    .drag-handle-delay,
    .drag-handle-8--delay,
    .drag-handle-delay-80 {
      overflow: hidden;
      height: 0;
      width: 0;
      opacity: 0;
      transition:
        width 0ms 300ms,
        height 0ms 300ms,
        opacity 300ms ease-in;
    }

    &:hover {
      .drag-handle,
      .drag-handle-delay,
      .drag-handle-8--delay {
        opacity: 1;
      }

      .drag-handle-delay-80 {
        opacity: 0.8;
      }

      .drag-handle-8--delay {
        height: 32px;
        width: 32px;
        transition: opacity 300ms ease-out;
      }

      .drag-handle,
      .drag-handle-delay,
      .drag-handle-delay-80 {
        height: 24px;
        width: 24px;
        transition: opacity 300ms ease-out;
      }
    }
  }
}

textarea {
  scrollbar-color: #757575 white;
  scrollbar-width: thin;
  cursor: auto;
}

textarea::-webkit-scrollbar {
  width: 5px;
  cursor: default;
}

textarea::-webkit-scrollbar-track {
  background: white;
}

textarea::-webkit-scrollbar-thumb {
  background: #757575;
}

/**
* NGX Color Picker
*/
.color-picker {
  @apply border-0 #{!important};
}

.color-picker input {
  @apply border-gray-300f rounded-md border bg-white text-sm shadow-sm #{!important};
}

.color-picker input:focus {
  @apply border-primary ring-primary ring-1 #{!important};
}

.color-picker input {
  // border-width: 1px !important;
  // padding-top: 0.5rem !important;
  // padding-right: 0.75rem !important;
  // padding-bottom: 0.5rem !important;
  // padding-left: 0.75rem !important;
  height: 38px !important;
  // border: 1px !important;
}

// .color-picker .saturation-lightness {
//   height: 200px !important;
// }

.color-picker .preset-area .preset-color {
  border-radius: 100% !important;
  height: 24px !important;
  width: 24px !important;
}

.color-picker .type-policy {
  top: 224px !important;
}

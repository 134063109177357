@import 'vidstack/player/styles/default/theme.css';
@import 'vidstack/player/styles/default/layouts/audio.css';
@import 'vidstack/player/styles/default/layouts/video.css';

:root {
  --media-menu-border-radius: var(--border-radius-lg);
  --media-menu-item-border-radius: var(--border-radius-lg);
  --media-menu-section-border-radius: var(--border-radius-lg);
  --video-border: 0px;
  --video-border-radius: var(--border-radius-md);
}

[data-media-provider] video {
  aspect-ratio: 16 / 9;
}

[data-media-player][data-view-type='video'] {
  aspect-ratio: 16 / 9;
  background-color: var(--video-bg, black);
}

[data-media-player][data-view-type='video']:not([data-fullscreen]) {
  border-radius: var(--video-border-radius, 6px);
  border: var(--video-border, 1px solid rgb(255 255 255 / 0.1));
}

media-google-cast-button {
  display: none;
}

.custom-overlay-container {
  // has to be greater then intercom, better soltuion is to set zindex of intercomn lower
  z-index: 2147483002;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.vds-video-layout {
  pointer-events: auto;
  --media-thumbnail-aspect-ratio: 16 / 9;
}

/*******************
 Main container
*******************/

.error-card {
  h1 {
    font-size: 210px;
    font-weight: 900;
    line-height: 210px;
    margin: 0px;
    text-shadow: 4px 5px 1px #b3bbbf;
  }
}

:root {
  --white: rgb(255, 255, 255);
  --white-alpha: 255 255 255;
  --black: rgb(0, 0, 0);

  --dark-bg: #18181b;

  --base-font-size: 16px;
}

body.isDarkTheme {
  --white: rgb(31, 31, 36);
  --white-alpha: 31 31 36;
  --black: rgb(255, 255, 255);
  --primary: 115 143 252;

  .btn-primary {
    @apply text-dwhite;
  }
}

body.isDarkTheme .mdc-menu-surface,
body.isDarkTheme .mat-mdc-paginator,
.mat-mdc-dialog-container .mdc-dialog__surface,
body.isDarkTheme .mat-expansion-panel,
body.isDarkTheme .mat-mdc-table,
body.isDarkTheme .mdc-data-table__header-cell,
body.isDarkTheme .mat-stepper-horizontal,
body.isDarkTheme .mat-stepper-vertical {
  background-color: var(--white) !important;
}

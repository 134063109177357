/*******************
 Topbar
*******************/
.header-toolbar {
  &.topbar {
    padding: 0px;
  }
  .navbar-header {
    .navbar-brand {
      display: flex;
      line-height: 18px;

      .dark-logo {
        display: none;
      }
      b {
        margin-right: 5px;
      }
    }
  }
}

.header-toolbar__profile-pic {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 100%;
}
/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

span[varname] {
  @apply bg-gray-200;
  border-radius: var(--border-radius-md);
  padding: 0.1rem 0.4rem;
  margin-block: 0.2rem;
  margin-right: 0.2rem;
  cursor: pointer;
}

span[varname].ProseMirror-selectednode {
  outline: 2px solid rgb(var(--primary) / 1);
  outline-offset: -2px;
}

$mini-sidebar-width: 80px;
/*******************
Main sidebar
******************/

/*
* Hot Toast Positionierung
*/
.sidebar-margin {
  @media (min-width: 1024px) and (max-width: 1279px) {
    margin-left: 70px !important;
  }
  @media (min-width: 1280px) {
    margin-left: 280px !important;
  }
  @media (min-width: 1536px) {
    margin-left: 320px !important;
  }
}

.menu-sidebar {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  --tw-bg-opacity: 1;
  // background: rgb(249 250 251 / var(--tw-bg-opacity));
  background: var(--white);
  border-right: 1px solid;
  @apply border-gray-200;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
  }

  .school-icon-container,
  .menu2-container {
    @apply border-gray-200;
  }

  .school-icon {
    @apply text-gray-800;
  }

  #mspoticon {
    color: #3e444d;
  }

  .menu-list-item {
    height: auto;
    &:hover {
      background: none;
    }

    a {
      padding: 1px 6px;
      margin-left: 12px;
      margin-right: 12px;
      margin-bottom: 4px;
      align-items: center;
      display: flex;
      font-size: 0.875rem;
      white-space: nowrap;
      @apply text-gray-700;
      font-weight: 500;
      border-radius: var(--border-radius-lg);
      border: 1px solid transparent;

      ng-icon {
        @apply mr-0.5 text-lg;
      }

      @media (min-width: 1024px) and (max-width: 1279px) {
        padding: 4px 0px 4px 0px;
        margin: 0 4px;
        justify-content: center;
      }

      &:hover {
        @apply border border-gray-200 bg-gray-50;
      }

      &.selected {
        @apply font-medium text-gray-900;
        @apply dark:bg-primary/10 bg-primary/5 border-primary/20 border;
      }
    }
  }

  .mat-nav-list .sub-item {
    display: block;
    margin-top: -15px;
    a {
      padding-left: 45px;
      height: 50px;
      margin-bottom: 0px;
    }
    .child-sub-item {
      display: none;
      margin-top: -15px;
      a {
        padding: 0 0 0 55px;
        height: 50px;
        margin-bottom: 0px;
      }
    }
  }
  .saperator {
    cursor: text;
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 25px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.isDarkTheme {
  #mspoticon {
    color: #ffffff;
  }
}

.sidebar--dark {
  .menu-sidebar {
    @apply bg-gray-800;

    .school-icon-container,
    .menu2-container {
      @apply border-gray-800;
    }

    .school-icon {
      @apply text-gray-100;
    }

    #mspoticon {
      color: #ffffff;
    }

    .menu-list-item {
      a {
        @apply text-gray-100;

        .menu-icon {
          background: none;
        }

        &.selected {
          @apply bg-gray-900;
          color: white;

          .menu-icon {
            @apply text-gray-100;
          }
        }
      }
    }
  }
}

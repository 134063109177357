/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

/* purgecss start ignore */
/* @import './material_custom.scss'; */
// @import './material.scss';
/* purgecss end ignore */

@import './variable.scss';
@import './colors.scss';

@import './shared.scss';
@import './widgets.scss';
@import './shared/client-config.scss';

@import './spinner.scss';
@import './app.scss';
@import './header.scss';
@import './pages.scss';
// @import './sidebar.scss';

@import './responsive.scss';
@import './app_dialog.scss';

@import './editor-new.scss';
@import './shared/editor-content.scss';
@import './email.scss';

@import './card.scss';
@import './vidstack.styles';
@import './shared/dialog';
@import './shared/skeletons.scss';

$bodyfont: Inter, sans-serif;

@import './material_custom.scss';
@import '../../../assets/styles/style.scss';

:root {
  --primary: 24 64 220;

  --dial-background-color: rgb(var(--primary) / 1);

  --client-box-list-width: 320px;
}

.double-bounce1,
.double-bounce2 {
  background-color: rgb(var(--primary) / 1);
}

.page-content {
  max-width: 1112px;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.max-width-client {
  max-width: var(--client-max-width);
  margin: 0 auto;
}

.header-bg {
  background-color: rgb(var(--header-bg-color, var(--gray-50) / 0.85));
}

.green {
  color: green !important;
}

.main-color {
  color: #000000de !important;
}

.black {
  color: black !important;
}

.gray {
  color: gray !important;
}

.lightgreen {
  color: #0c7310 !important;
}

.red {
  color: red !important;
}

.slategray {
  color: slategray !important;
}

.xng-breadcrumb-list {
  min-height: 24px;
}

.xng-breadcrumb-link {
  color: #2563eb !important;
  font-size: 14px;
}

.xng-breadcrumb-trail {
  color: #99abb4 !important;
  font-size: 14px;
}

.lecture-icon {
  color: #434a50 !important;
}

.edit-icon {
  color: black;
  position: absolute;
  top: 0.1rem;
  right: -2rem;
  font-size: 1.5rem;
}

/*
* Quill Editor
*/
.ql-toolbar.ql-snow {
  position: sticky;
  top: 55px;
  background: white;
  z-index: 10;
  @media (min-width: 960px) {
    top: 64px;
  }
}

mspot-portal-sidebar {
  .ql-toolbar.ql-snow {
    top: 0px;
  }
}

.ql-editor {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
  }
}

.ql-container {
  overflow-x: auto;
  height: 350px;
  @media (min-width: 960px) {
    height: 500px;
  }
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'Link:' !important;
  font-size: 0.875rem;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: '\2714' !important;
  font-size: 0.875rem;
}

.cm-s-material {
  border-radius: var(--border-radius-lg);
}

.mat-drawer {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
  }
}

.ql-scroll {
  min-height: 200px;
  overflow: visible;
  // overflow-y: auto;
  // overflow-x: hidden;
  // scrollbar-color: #757575 white;
  // scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
  }
}

.ql-container.ql-bubble {
  z-index: 99;
}

.ql-editor {
  // background-color: white;

  a,
  a:visited {
    color: rgb(var(--primary) / 1);
    text-decoration: underline;
  }

  a:hover {
    color: rgb(var(--primary) / 1);
  }
}

.ql-bubble .ql-editor {
  border: 1px solid;
  @apply border-gray-300;
  border-radius: var(--border-radius-md);
  min-height: 200px;
  overflow: auto;
}

.ql-bubble .ql-editor:focus {
  border-color: rgb(var(--primary) / 1) !important;
  --tw-ring-color: rgb(var(--primary) / 1) !important;

  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.ql-bubble .ql-tooltip-editor input[type='text']:focus {
  box-shadow: none;
}

.ql-bubble .ql-tooltip,
.ql-floating-menu,
.ql-bubble .ql-picker-options {
  background-color: var(--dark-bg) !important;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom: 6px solid var(--dark-bg) !important;
}

.ql-container {
  font-family: inherit !important;
  font-size: inherit !important;
}

.media-object {
  background: var(--white);
}

.no-data {
  margin-top: 10px;
  font-size: 0.85rem;
  color: var(--grey-text);
}

table {
  width: 100%;
}

.mat-mdc-row.clickable:hover {
  @apply bg-gray-100;
  cursor: pointer;
}

.mat-mdc-cell,
.mat-mdc-header-cell {
  padding-right: 16px;
}

.mat-column-actions {
  color: var(--grey-text);
}

.timepicker__header,
.clock-face__number > span.active,
.clock-face__clock-hand {
  background-color: rgb(var(--primary) / 1) !important;
}

.timepicker-button {
  color: rgb(var(--primary) / 1) !important;
}

.CodeMirror {
  font-size: 0.875rem;
  height: 800px;
}

.mat-input-element:disabled {
  color: rgba(0, 0, 0, 1) !important;
}

table[mspotTable] tr.mat-mdc-header-row {
  height: unset !important;
}

.CodeMirror {
  height: 350px !important;
}

.dashboard-side-panel > .cdk-virtual-scroll-content-wrapper {
  @apply flex flex-col gap-1 overflow-y-auto p-6;
}

.bottom-sheet-actions {
  border-radius: 1rem 1rem 0 0;
}

.billing-address-bottom-sheet
  > mat-bottom-sheet-container.mat-bottom-sheet-container {
  @apply bg-dwhite bottom-sheet-actions;
  padding: 16px !important;
}

mspot-admin-onboarding {
  .mat-expansion-panel {
    --white: rgb(var(--mspot-white) / 1);
  }
  .mat-expansion-panel-body {
    padding: 0 20px 16px !important;
    @media (min-width: 768px) {
      padding: 0 24px 24px !important;
    }
  }

  .mat-expansion-panel-header {
    padding: 0 20px !important;
    @media (min-width: 768px) {
      padding: 0 24px !important;
    }
  }
}

/*******************
Global Styles
*******************/

* {
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: $bodyfont;
  // margin: 0;
  // overflow: hidden;
  color: $bodytext;
  font-weight: 400;
  font-size: var(--base-font-size);
  // height: 100vh;
  //overflow-y: scroll;
}

body,
html {
  overscroll-behavior: none;
}

body.portal-open {
  overflow-y: hidden;
  padding-right: var(--body-scrollbar-width);
}

html {
  position: relative;
  min-height: 100%;
  height: 100vh;
  background: $white;
  font-size: var(--base-font-size);
  -webkit-tap-highlight-color: transparent;
}

a.link {
  @apply text-gray-500;

  text-decoration: none;

  &:hover,
  &:focus {
    color: rgb(var(--primary) / 1);
    cursor: pointer;
    text-decoration: underline;
  }

  &--inherit {
    color: inherit !important;
  }

  &--bright:hover {
    filter: brightness(115%);
  }

  &--dark {
    @extend .link;
    color: black;
  }

  &--dark-orange {
    @extend .link--dark;
    &:hover {
      color: sandybrown;
    }
  }
}

.link--in-text {
  color: rgb(var(--primary) / 1);
  &:active,
  &:visited {
    color: rgb(var(--primary) / 1);
  }
  &:hover {
    filter: brightness(0.85);
    text-decoration: underline;
    cursor: pointer;
  }
}

.handle {
  &:hover,
  &:focus {
    cursor: move;
  }
}

/*******************
Text Colors
*******************/

hr {
  border: 0px;
  height: 1px;
  background: $border;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-lg {
  line-height: 48px !important;
}

/*******************
froms
*******************/
.form-control {
  border: 0px;
  line-height: 25px;
  padding: 10px;
  width: 100%;
}

/*******************
Mat card
*******************/
.mat-mdc-card {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  margin: 15px;
  padding: 0px !important;
  border-radius: $radius;

  .mat-mdc-card-content {
    font-size: 1rem;
    line-height: 23px;
    padding: 24px;
  }
  .mat-mdc-card-image {
    width: calc(100% + 0px);
    margin: 0 0px 0px 0px;
  }
  .mat-mdc-card-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .mat-mdc-card-subtitle {
    margin-bottom: 20px;
  }
  .mat-mdc-card-header {
    padding: 12px;
    border-radius: $radius $radius 0 0;
    .mat-mdc-card-title {
      margin-bottom: 2px;
      font-size: 20px;
    }
    .mat-mdc-card-subtitle,
    .mat-mdc-card-subtitle:not(:first-child) {
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
    &.text-white {
      .mat-mdc-card-title {
        color: $white;
      }
      .mat-mdc-card-subtitle {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .mat-mdc-card-actions {
    margin: 0px !important;
  }
}

/*******************
Grid
*******************/
.row {
  margin: 0 -10px;
}
/*******************
Table
*******************/
.table {
  width: 100%;
  text-align: left;
}
.table-rasponsive {
  overflow: auto;
  .mat-mdc-table {
    min-width: 280px;
  }
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      white-space: nowrap;
    }
  }
  .mat-mdc-cell {
    white-space: nowrap;
  }
}

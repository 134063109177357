:root {
  --correct: #43a047;
  --wrong: #e53935;

  --correct-bg: #e6f4ea;
  --correct-bg-dark: #144f25;

  --wrong-bg: #fce8e6;
  --wrong-bg-dark: #912c25;

  --grey-bg: #455a64;
  --grey-text: rgba(0, 0, 0, 0.54);

  --border--light: rgba(0, 0, 0, 0.13);
  --border-radius: 0.25rem;
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 0.75rem;
  --border-radius-2xl: 1rem;
  --border-radius-3xl: 1.5rem;
  --border-radius-4xl: 2rem;
  --mdc-dialog-container-shape: var(--border-radius-lg);

  --bg-light: #f2f4f8;
  --bg-hover-light: var(--bg-light);
  --bg-dark: #303030;
  --bg-hover-dark: var(--dark);

  --cropper-outline-color: rgba(0, 0, 0, 0.6);
}

body {
  // Custom Color Picker
  --ccp-client-primary: var(--client-primary);
  --ccp-gray-50: rgb(var(--gray-50) / 1);
  --ccp-gray-100: rgb(var(--gray-100) / 1);
  --ccp-gray-200: rgb(var(--gray-200) / 1);
  --ccp-gray-300: rgb(var(--gray-300) / 1);
  --ccp-gray-400: rgb(var(--gray-400) / 1);
  --ccp-gray-500: rgb(var(--gray-500) / 1);
  --ccp-gray-600: rgb(var(--gray-600) / 1);
  --ccp-gray-700: rgb(var(--gray-700) / 1);
  --ccp-gray-800: rgb(var(--gray-800) / 1);
  --ccp-gray-900: rgb(var(--gray-900) / 1);
  --ccp-gray-950: rgb(var(--gray-950) / 1);
  --ccp-white: var(--white);
  --ccp-black: var(--black);
}

.card-ring-color {
  --tw-ring-color: rgb(var(--gray-900) / 0.02);
}

.isDarkTheme {
  --hot-toast-close-btn-background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

/*
 * Header Styles
 */
.header-blur {
  backdrop-filter: saturate(180%) blur(20px);
}

.header-blur-light {
  backdrop-filter: saturate(180%) blur(8px);
}

/*
  example: https://gist.github.com/pixelpicosean/7193243
*/

.mat-mdc-dialog-surface:hover,
.scrollbar:hover,
.scrollbar\:horizontal:hover {
  &::-webkit-scrollbar-thumb {
    @apply bg-gray-300;
  }

  &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active {
    @apply bg-gray-500;
  }
}

.mat-mdc-dialog-surface,
.scrollbar,
.scrollbar\:horizontal {
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: var(--border-radius-lg);
  }

  &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active {
    background: transparent;
  }
}

.mat-mdc-dialog-surface,
.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }
}

.scrollbar\:horizontal {
  &::-webkit-scrollbar {
    height: 5px;
  }
}

.scrollbar-hide,
.emoji-mart-scroll {
  /* IE and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;

  /* Safari and Chrome */
  &::-webkit-scrollbar {
    display: none;
  }
}

.ql-img-chrome-fix {
  image-rendering: -webkit-optimize-contrast;
}

.quill-overflow-visible .ql-editor {
  overflow: visible;
}

.mat-drawer-container {
  height: 100%;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-input {
  /* min-width: 16.3125rem; */
  min-width: 13.75rem;
}
.min-w-input-prefix {
  min-width: 15.5rem;
}

.table-container {
  overflow: auto;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe,
.video-responsive canvas,
.video-responsive video {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.cursor--text:hover {
  cursor: text;
}

.pointer {
  cursor: pointer;
}

.cursor--pointer:hover {
  cursor: pointer;
}

.link--black {
  color: #000000de;
}

/* Remove in next release */
.max-width {
  max-width: 1280px;
  margin: 0 auto;
}

.red {
  background-color: red !important;
}

.skyblue {
  color: skyblue !important;
}

.visible {
  visibility: visible;
}

.black {
  color: black;
}

.disabled-color {
  color: #0000008a !important;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}

.brd {
  border-radius: 4px;
}

.fill-height {
  height: 100%;
}

.disable-element {
  opacity: 0.5;
  pointer-events: none;
}

.disable-plan-element {
  opacity: 0.5;
}

/**
* Titles
*/

.title {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
}

.info-icon {
  font-size: 1rem;
  margin-left: 5px;
  width: 1rem;
  height: 1rem;
  color: #13232f !important;
  cursor: default;
  touch-action: auto !important;
}

/**
* Font-Sizes
*/

.lh-0 {
  line-height: 0;
}

/**
* Touch Helper
*/

.taauto {
  touch-action: auto !important;
}

.clear-space {
  padding: 1px;
}

/**
* NavCard
*/
.lib-nav-card {
  margin: 15px 0 !important;
}

.nav-card-fill-height {
  height: calc(100% - 10px);
}

@media (min-width: 600px) {
  .lib-nav-card {
    margin: 15px !important;
  }
}

/**
* NgxFileDrop
*/
.file-drop {
  height: 120px;
  border: 2px dotted #2563eb;
  border-radius: 10px;
}

.file-drop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  color: #2563eb;
}

/*
* Image Cropper
*/
.source-image {
  display: inline;
}

img.ngx-ic-source-image {
  min-height: 120px !important;
  max-height: calc(95vh - 250px) !important;
}

.ngx-ic-cropper:before {
  content: ' ';
  box-sizing: border-box;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  left: 33.33%;
  right: 33.33%;
  border-top: 0;
  border-bottom: 0;
  pointer-events: none;
}
.ngx-ic-cropper:after {
  content: ' ' !important;
  box-sizing: border-box !important;
  position: absolute !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  top: 33.33% !important;
  bottom: 33.33% !important;
  left: 0 !important;
  right: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/*
* Form Helpers
*/

[disabled='disabled'] {
  opacity: 0.5;
}

.button-pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse 1s;
  animation-iteration-count: 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }

  /* 100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  } */
}

/*******************
 Login register
*******************/
.login-register {
  min-height: 100%;
  display: flex;
  background-size: cover;
  align-items: center;
  .login-register-box {
    max-width: 400px;
    min-width: 280px;
    width: 100%;
    margin: 0 auto;
    vertical-align: middle;
  }
}

.notification-title {
  b {
    font-weight: 500;
  }
}

.fill > svg {
  fill: rgb(var(--primary) / 1);
}

/*
* Hot Toast
*/
hot-toast-container > div {
  z-index: 10001 !important;
}

/*
* Emoji Mart
*/

.no-categories {
  .emoji-mart {
    emoji-mart-anchors {
      display: none;
    }
  }
}

.no-border .emoji-mart {
  border: none !important;
}

.emoji-mart-category-label span {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.emoji-mart-anchor-selected {
  color: rgb(var(--primary) / 1) !important;
}

.emoji-mart-anchor > div {
  display: flex;
  justify-content: center;
}
.emoji-mart-anchor-bar {
  background-color: rgb(var(--primary) / 1) !important;
}
.emoji-mart-search-icon {
  top: 6px !important;
}
.emoji-mart-category-label {
  margin-top: 1px;
}

/*
* HotToast angular 15 workaround
* https://github.com/ngneat/hot-toast/issues/113
*/
@keyframes hotToastEnterAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastEnterAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastExitAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 130px, -1px) scale(0.5);
  }
}

@keyframes hotToastExitAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -130px, -1px) scale(0.5);
  }
}

/*
remove when tailwind supports
*/
@media (min-width: 1024px) {
  .lg\:whitespace-nowrap {
    white-space: nowrap !important;
  }
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */
.toolbar {
  height: 64px;
}

.header-top-distance {
  top: 64px;
}

.header-top-distance-heading {
  top: 126px;
}

@media (max-width: 1023px) {
  .header-top-distance-heading {
    top: 64px;
  }
}

@media (max-width: 959px) {
  .toolbar {
    height: 55px !important;
  }

  .header-top-distance,
  .header-top-distance-heading {
    top: 54px;
  }
}

@media (min-width: 1024px) {
  .page-content {
    padding: 0 1rem 5rem;
  }

  .page-content-x {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .page-content-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .equalize-page-content-x {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    width: 190px;
    flex-shrink: 0;
    .navbar-brand {
      padding-top: 0px;
    }
  }
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */

@media (max-width: 767px) {
  /**
  * Breadcrumb
  */
  li.xng-breadcrumb-item {
    margin: 0;
  }
  .xng-breadcrumb-item,
  .xng-breadcrumb-trail {
    font-size: 0.8rem;
  }
}

@media print {
  html .page-wrapper {
    height: 100%;
  }
}

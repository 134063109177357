@use '@angular/material' as mat;

$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Inter, sans-serif',
);

@include mat.core();
// @include mat.all-component-typographies($custom-typography);

@include mat.datepicker-typography($custom-typography);
@include mat.dialog-typography($custom-typography);
@include mat.divider-typography($custom-typography);
@include mat.expansion-typography($custom-typography);

@include mat.menu-typography($custom-typography);

@include mat.paginator-typography($custom-typography);
@include mat.progress-bar-typography($custom-typography);
@include mat.progress-spinner-typography($custom-typography);

@include mat.stepper-typography($custom-typography);
@include mat.sort-typography($custom-typography);
@include mat.table-typography($custom-typography);
@include mat.tabs-typography($custom-typography);
@include mat.tooltip-typography($custom-typography);

/*Material Theme Colors*/
$primary: mat.m2-define-palette(mat.$m2-blue-palette, 600);
$accent: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 500);

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);

@mixin custom-themes($theme-or-color-config) {
  @include mat.core-theme($theme-or-color-config);

  @include mat.datepicker-theme($theme-or-color-config);
  @include mat.dialog-theme($theme-or-color-config);
  @include mat.divider-theme($theme-or-color-config);
  @include mat.expansion-theme($theme-or-color-config);

  @include mat.menu-theme($theme-or-color-config);

  @include mat.paginator-theme($theme-or-color-config);
  @include mat.progress-bar-theme($theme-or-color-config);
  @include mat.progress-spinner-theme($theme-or-color-config);

  @include mat.stepper-theme($theme-or-color-config);
  @include mat.sort-theme($theme-or-color-config);
  @include mat.table-theme($theme-or-color-config);
  @include mat.tabs-theme($theme-or-color-config);

  @include mat.tooltip-theme($theme-or-color-config);
}

@include custom-themes($theme);

body.isDarkTheme {
  @include mat.core-color($dark-theme);

  @include mat.datepicker-color($dark-theme);
  @include mat.dialog-color($dark-theme);
  @include mat.divider-color($dark-theme);
  @include mat.expansion-color($dark-theme);

  @include mat.menu-color($dark-theme);

  @include mat.paginator-color($dark-theme);
  @include mat.progress-bar-color($dark-theme);
  @include mat.progress-spinner-color($dark-theme);

  @include mat.stepper-color($dark-theme);
  @include mat.sort-color($dark-theme);
  @include mat.table-color($dark-theme);
  @include mat.tabs-color($dark-theme);

  @include mat.tooltip-color($dark-theme);
}

:root {
  --primary: 37 99 235;
  --client-primary: #2563eb;

  --client-header: rgb(255, 255, 255);
  --client-header-bg: rgb(255, 255, 255);
  --client-header-bg-80: rgb(255, 255, 255, 0.8);
  --client-header-color: rgb(var(--gray-900) / 1);
  --client-header-link-tab-color: var(--client-primary);
  --client-header-border-color: rgb(var(--gray-300) / 1);
  --client-header-icon-color: rgb(var(--gray-600) / 1);
  --client-header-light-color: rgb(var(--gray-600) / 1);
  --client-header-icon-hover-color: rgb(var(--gray-700) / 1);

  --client-body-bg: rgb(249, 249, 251);
  --client-body-bg-80: rgb(249, 249, 251, 0.8);

  --client-footer-bg: #343a40;
  --client-footer-color: #ffffff;
  --client-footer2-color: #868e96;

  // 1180px + 32px (16*2) padding
  --client-max-width: 1212px;

  // 856px + 32px (16*2) padding
  --client-max-width-course-no-boxes: 888px;

  // 784px + 32px (16*2) padding
  --client-max-width-community-no-sidebar: 816px;

  --client-max-width-text: 960px;
  --client-box-list-width: 320px;

  --scroll-fade-bg-dark: rgb(25, 25, 36);
}

.client-page-bg {
  background: var(--client-body-bg);
  color: var(--client-body-color);
}

.client-header-bg {
  background: var(--client-header-bg) !important;
  color: var(--client-header-light-color) !important;
}

.client-header-bg\/80 {
  background: var(--client-header-bg-80) !important;
  color: var(--client-header-light-color) !important;
}

.client-header-bg--sidenav {
  background: var(--client-body-bg-80) !important;
  color: var(--client-header-light-color) !important;
}

.isDarkTheme {
  --white-80: rgb(31, 31, 36, 0.8);
  --client-header-light-color: rgb(var(--gray-600) / 1);

  .client-page-bg {
    @apply bg-gray-50;
    color: white;
  }

  .client-header-bg--sidenav {
    @apply bg-gray-50/80 #{!important};
    color: white !important;
  }

  .client-header-bg,
  .client-header-bg\/80 {
    background: var(--white-80) !important;
    color: white !important;
  }
}

.search-border {
  border-color: var(--client-header-border-color) !important;
}

.client-header-link-active,
.hover\:client-header-link-active:hover {
  color: var(--client-header-color) !important;
}

.dark .client-header-link-active.client-header-link-active-border {
  color: #ffffff !important;
}

.dark .client-header-link-active-border {
  border-bottom-color: #ffffff !important;
}
.client-header-link-active-border {
  border-bottom-color: var(--client-header-link-tab-color) !important;
}

.isDarkTheme {
  .client-header-icon {
    @apply text-gray-500;
    &:hover {
      @apply text-gray-700;
    }
  }
}

.client-header-icon {
  color: var(--client-header-icon-color);
  &:hover {
    color: var(--client-header-icon-hover-color);
  }
}
.group:hover .group-hover\:client-header-icon {
  color: var(--client-header-icon-hover-color);
}

.font-client-header {
  color: var(--client-header-color);
}
.font-client-header-icon {
  color: var(--client-header-icon-color);
}
.font-client-header-light {
  color: var(--client-header-light-color);
}

.dark-logo {
  display: none;
}

.header__link,
.header__link--border {
  font-weight: 500;
  font-size: 0.875rem;
  color: inherit;
  // padding: 0 16px;
}

.header__link--border {
  border-color: transparent;
}

body {
  background: var(--client-footer-bg);
}
.client-footer {
  background: var(--client-footer-bg);
  color: var(--client-footer-color);
}
.lower-footer {
  color: var(--client-footer2-color);
}

.correct {
  &-bg {
    background-color: var(--correct-bg);
  }
  &-title {
    color: var(--correct);
  }
}

.wrong {
  &-bg {
    background-color: var(--wrong-bg);
  }
  &-title {
    color: var(--wrong);
  }
}

body.isDarkTheme {
  @apply bg-gray-50;
  color: white;
}

.isDarkTheme {
  .light-logo {
    display: none;
  }
  .dark-logo {
    display: inline;
  }

  /* breadcrumb */
  .xng-breadcrumb-link {
    color: white;
  }

  .xng-breadcrumb-separator {
    color: white;
  }

  .xng-breadcrumb-trail {
    color: white;
  }

  .media-object:hover,
  .media-item:hover,
  .playlist-item:hover {
    @apply bg-gray-50;
  }

  .playlist-item--active {
    @apply bg-gray-800 sm:bg-gray-50 dark:bg-gray-200;
  }

  .correct-bg {
    background-color: var(--correct-bg-dark);
  }
  .wrong-bg {
    background-color: var(--wrong-bg-dark);
  }

  .client-footer {
    @apply bg-gray-50;

    color: white;
  }
  .mat-drawer-container {
    background: #212121;
  }
  .lower-footer {
    color: #868e96;
  }
}

.hover\:card {
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  &:hover {
    @apply shadow-md #{!important};
    // transform: translateY(-0.125rem);
    transform: scale(1.01);
  }
}

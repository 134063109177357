.skeleton {
  background: linear-gradient(
    113deg,
    rgb(var(--gray-200) / 28%),
    rgb(var(--gray-100) / 58%),
    rgb(var(--gray-200) / 75%)
  );
  background-size: 200% 100%;
  animation: pulse 1.25s infinite ease-in-out;
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  to {
    opacity: 1;
    background-position-x: -200%;
  }
}

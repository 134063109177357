/*Theme Colors*/
$light: #f2f4f8;
$white: #ffffff;
$muted: #99abb4;

$sidebar: #fff;
$sidebar-white: #e5edef;
$bodycolor: #eef5f9;
$bodytext: #111827;
$sidebar-text: #8f999e;
$sidebar-icons: #99abb4;

$light-text: #a6b7bf;
$themecolor: #2563eb;
$themecolor-alt: #2563eb;
$themecolor-dark: #028ee1;

/*Extra Variable*/

$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

.media-object {
  border-bottom: 1px solid #00000021;

  text-decoration: none;
  padding: 9px 15px;
  min-height: 65px;

  overflow: hidden;

  &:hover {
    background: var(--bg-hover-light);
  }

  &--no-drip {
    @extend .media-object;
    opacity: 0.5;
  }

  &--no-hover {
    @extend .media-object;

    border-bottom: unset;
    padding: 0;

    &:hover {
      background: none !important;
    }
  }

  &--clean {
    text-decoration: none;
    padding: 15px 9px;
  }
}

.media-object__img {
  padding: 10px;
  max-width: 120px;
  max-height: 95px;
  border-radius: 14px;

  &--no-pad {
    max-width: 100%;
    border-radius: var(--border-radius-lg);
    min-height: 80px;
  }

  &--100 {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }

  &--max500 {
    padding: 10px;
    width: 95%;
    max-width: 500px;
    border-radius: 14px;

    &--no-pad {
      width: 100%;
      max-width: 500px;
      border-radius: var(--border-radius-lg);
    }
  }
}

.media-object__heading {
  margin: 0;

  &--big {
    margin: 0;
    font-size: 1.5rem;
  }

  &--small {
    @extend .media-object__heading;
    font-size: 19px;
  }
}

.media-object__desc {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $bodytext;
  white-space: nowrap;
}

/**
* Media Item
*/

.media-item {
  display: flex;

  align-items: center;
  text-decoration: none;
  min-height: 95px;

  overflow: hidden;

  &--no-drip {
    @extend .media-item;
    opacity: 0.5;
  }

  &--no-hover {
    @extend .media-item;

    border-bottom: unset;
    padding: 0;

    &:hover {
      background: none !important;
    }
  }

  &--clean {
    border-bottom: 1px solid #00000021;
    text-decoration: none;
    padding: 12px 9px 12px 9px;

    &--pbd {
      padding: 12px 9px 24px 9px;
    }
  }
}

@media (min-width: 600px) {
  .media-item {
    // padding-bottom: 32px;

    &--no-hover {
      padding: 0;
    }
  }

  .media-item--heading {
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-weight: 400;
  }
  .media-item--subtitle {
    font-size: 0.95rem;
  }
}

/**
* Playlist
*/

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

.playlist-item {
  height: 54px;
  margin-bottom: 10px;
  overflow: hidden;

  @media (min-width: 1280px) {
    height: 67px;
  }

  &:hover {
    @apply sm:bg-gray-100;
    cursor: pointer;
  }

  &__heading {
    font-size: 0.8125rem;
    line-height: 1rem;
    overflow: hidden;

    @media (min-width: 1280px) {
      font-size: 0.875rem;
    }
  }

  &--active {
    @apply bg-gray-100;

    .hidden {
      visibility: visible;
    }
  }
}

/**
* General Styles
*/

.spacer {
  padding: 0.01rem;
}

.box__description {
  font-size: 0.875rem;
}

.toast {
  border-top: 1px solid rgb(var(--gray-300) / 1) !important;
  border-left: 1px solid rgb(var(--gray-300) / 1) !important;
  border-right: 1px solid rgb(var(--gray-300) / 1) !important;
}

/*
* Image
*/
.hover-dark-bg:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.hover\:show,
.hover\:show-6,
.hover\:show-9,
.hover\:show-10 {
  opacity: 0;
  overflow: hidden;
  transition:
    height 0ms 150ms,
    opacity 150ms ease-out;
}

.sm\:hover\:show {
  @media (min-width: 640px) {
    opacity: 0;
    overflow: hidden;
    transition:
      height 0ms 150ms,
      opacity 150ms ease-out;
  }
}

.hover\:show-6,
.hover\:show-9,
.hover\:show-10 {
  height: 0;
}
.hover\:hide {
  opacity: 1;
  transition:
    height 0ms 150ms,
    opacity 150ms ease-in;
}
.hover\:hide-delay {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.cdk-drop-list-dragging .hover-container:hover,
.cdk-drag-placeholder .hover-container:hover {
  .hover\:show,
  .hover\:show-6,
  .hover\:show-9,
  .hover\:show-10 {
    opacity: 0;
    transition: opacity 150ms ease-in;
  }
}

.hover\:show[aria-expanded='true'] {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.hover-container:hover {
  .hover\:show,
  .sm\:hover\:show,
  .hover\:show-6,
  .hover\:show-9,
  .hover\:show-10 {
    opacity: 1;
    transition: opacity 150ms ease-in;
  }

  .hover\:show,
  .sm\:hover\:show {
    height: auto;
  }
  .hover\:show-6 {
    height: 1.5rem;
  }
  .hover\:show-9 {
    height: 2.25rem;
  }
  .hover\:show-10 {
    height: 2.5rem;
  }

  .hover\:hide,
  .hover\:hide-delay {
    opacity: 0;
    pointer-events: none;
  }

  .hover\:hide {
    transition:
      height 0ms 300ms,
      opacity 300ms ease-out;
  }

  .hover\:hide-delay {
    transition:
      height 0ms 300ms,
      opacity 300ms ease-out;
  }
}
